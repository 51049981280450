import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface IEcommercePayload {
    item_id: string;
    item_name: string;
    item_brand: string;
    price: number;
    quantity: number;
}

export interface IItemEventPayload {
    value: number;
    currency: string;
    items: IEcommercePayload[];
}

export interface IAddPaymentInfoEventPayload extends IItemEventPayload {
    payment_type: string;
}

export interface IPurchaseEventPayload {
    transaction_id: string;
    value: number;
    tax: number;
    shipping: number;
    currency: string;
    items: IEcommercePayload[];
}

@Injectable({
    providedIn: 'root',
})
export class TrackingService {
    private readonly dataLayer = (window as any).dataLayer;

    public pageViewEvent(activatedRoute: ActivatedRoute) {
        this.sendEvent('page_view', {
            pagePath: activatedRoute.snapshot.url.join('/'),
            pageTitle: activatedRoute.snapshot.title,
        });
    }

    public clearEcommerce() {
        this.dataLayer.push({ ecommerce: null });
    }

    public sendEvent(event: string, payload: unknown) {
        this.dataLayer.push({
            event,
            ...payload!,
        });
    }

    public sendEcommerceEvent(event: string, payload: unknown) {
        this.clearEcommerce();

        this.sendEvent(event, { ecommerce: payload });
    }

    public addPaymentInfoEvent(payload: IAddPaymentInfoEventPayload) {
        return this.sendEcommerceEvent('add_payment_info', payload);
    }

    public addShippingInfoEvent(payload: IItemEventPayload) {
        return this.sendEcommerceEvent('add_shipping_info', payload);
    }

    public addToCartEvent(payload: IItemEventPayload) {
        return this.sendEcommerceEvent('add_to_cart', payload);
    }

    public beginCheckoutEvent(payload: IItemEventPayload) {
        return this.sendEcommerceEvent('begin_checkout', payload);
    }

    public purchaseEvent(payload: IPurchaseEventPayload) {
        return this.sendEcommerceEvent('purchase', payload);
    }

    public removeFromCartEvent(payload: IItemEventPayload) {
        return this.sendEcommerceEvent('remove_from_cart', payload);
    }

    public viewItemEvent(payload: IItemEventPayload) {
        return this.sendEcommerceEvent('view_item', payload);
    }
}

import { IBillingItems } from '../interfaces';

export interface IPlansToProducts {
    [key: string]: IBillingItems;
}

export const PlansToProducts: IPlansToProducts = {
    'lifetime-team-startup': {
        invoiceItems: [
            {
                priceId: 'price_1QCgi9IduehnyhXbjMlSemPV',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QCgkTIduehnyhXbCMIjZU3H',
                quantity: 10,
            },
        ],
    },
    'lifetime-team-startup-test': {
        invoiceItems: [
            {
                priceId: 'price_1QAue2IduehnyhXbi4B4HS5W',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1Q9pAPIduehnyhXbHH5AEJ9V',
                quantity: 10,
            },
        ],
    },
    'lifetime-team-sme': {
        invoiceItems: [
            {
                priceId: 'price_1QCgbGIduehnyhXbk2j0GAQC',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QCgdYIduehnyhXbgjslWVAl',
                quantity: 50,
            },
        ],
    },
    'lifetime-team-sme-test': {
        invoiceItems: [
            {
                priceId: 'price_1QAXPpIduehnyhXbLFosjJ8I',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QAXKoIduehnyhXbdva5CNyO',
                quantity: 50,
            },
        ],
    },
    'lifetime-team-enterprise': {
        invoiceItems: [
            {
                priceId: 'price_1QCgWYIduehnyhXbGUMbP43J',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QCgYxIduehnyhXbBx8I1hc0',
                quantity: 250,
            },
        ],
    },
    'lifetime-team-enterprise-test': {
        invoiceItems: [
            {
                priceId: 'price_1QAXSvIduehnyhXbV9GA7pde',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QAXNgIduehnyhXbMpWE5g50',
                quantity: 250,
            },
        ],
    },
    'lifetime-agency-startup': {
        invoiceItems: [
            {
                priceId: 'price_1QCgN2IduehnyhXb0TbPPhZw',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QCgUKIduehnyhXbRMeN4jJj',
                quantity: 10,
            },
        ],
    },
    'lifetime-agency-startup-test': {
        invoiceItems: [
            {
                priceId: 'price_1QAXVpIduehnyhXbwJSBiDki',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QAXaOIduehnyhXbbX14U9UA',
                quantity: 10,
            },
        ],
    },
    'lifetime-agency-sme': {
        invoiceItems: [
            {
                priceId: 'price_1QCgGNIduehnyhXb9M9HCJns',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QCgKlIduehnyhXbPVWCwIf2',
                quantity: 50,
            },
        ],
    },
    'lifetime-agency-sme-test': {
        invoiceItems: [
            {
                priceId: 'price_1QAuldIduehnyhXbN390pxdI',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QAXdKIduehnyhXbPAMIPp8N',
                quantity: 50,
            },
        ],
    },
    'lifetime-agency-enterprise': {
        invoiceItems: [
            {
                priceId: 'price_1QCg7RIduehnyhXbR6DPAwkI',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QCgCJIduehnyhXbQ2HWILyG',
                quantity: 250,
            },
        ],
    },
    'lifetime-agency-enterprise-test': {
        invoiceItems: [
            {
                priceId: 'price_1QAupfIduehnyhXbmvldm9Le',
            },
        ],
        subscriptionItems: [
            {
                priceId: 'price_1QAuj6IduehnyhXbHOh5ZaDi',
                quantity: 250,
            },
        ],
    },
};

// Object containing regex patterns for VAT/Tax numbers for various countries
const taxIdPatterns: Record<string, RegExp> = {
    AE: /^AE[0-9]{15}$/, // UAE TRN (15 digits)
    AU: /^AU[0-9]{9}$/, // Australia ABN (9 digits)
    AT: /^ATU[0-9]{8}$/, // Austria VAT (U followed by 8 digits)
    BE: /^BE0[0-9]{9}$/, // Belgium VAT (10 digits starting with 0)
    BG: /^BG[0-9]{9,10}$/, // Bulgaria VAT (9 or 10 digits)
    BR: /^[0-9]{14}$/, // Brazil CNPJ (14 digits)
    CA: /^[0-9]{9}$/, // Canada BN (9 digits)
    CH: /^CHE[0-9]{9}(MWST|TVA|IVA)?$/, // Switzerland VAT (CHE followed by 9 digits and optional suffix)
    CL: /^CL[0-9]{8,9}$/, // Chile TIN (8 or 9 digits)
    CN: /^[0-9]{15}$/, // China TIN (15 digits)
    CY: /^CY[0-9]{8}[A-Z]$/, // Cyprus VAT (8 digits followed by a letter)
    CZ: /^CZ[0-9]{8,10}$/, // Czech Republic VAT (8, 9, or 10 digits)
    DE: /^DE[0-9]{9}$/, // Germany VAT (9 digits)
    DK: /^DK[0-9]{8}$/, // Denmark VAT (8 digits)
    EE: /^EE[0-9]{9}$/, // Estonia VAT (9 digits)
    ES: /^ES[A-Z0-9][0-9]{7}[A-Z0-9]$/, // Spain VAT (1 letter or number, 7 digits, 1 letter or number)
    FI: /^FI[0-9]{8}$/, // Finland VAT (8 digits)
    FR: /^FR[A-HJ-NP-Z0-9]{2}[0-9]{9}$/, // France VAT (2 letters or numbers followed by 9 digits)
    GB: /^GB[0-9]{9}$/, // UK VAT (9 digits)
    GR: /^GR[0-9]{9}$/, // Greece VAT (9 digits)
    HK: /^[0-9]{8}$/, // Hong Kong BR (8 digits)
    HU: /^HU[0-9]{8}$/, // Hungary VAT (8 digits)
    ID: /^ID[0-9]{15}$/, // Indonesia NPWP (15 digits)
    IE: /^IE[0-9][A-Z0-9\+\*][0-9]{5}[A-Z]$/, // Ireland VAT (Complex format, mix of letters and digits)
    IL: /^IL[0-9]{9}$/, // Israel VAT (9 digits)
    IN: /^[0-9]{15}$/, // India GSTIN (15 digits)
    IT: /^IT[0-9]{11}$/, // Italy VAT (11 digits)
    JP: /^JP[0-9]{12}$/, // Japan CN (12 digits)
    KR: /^KR[0-9]{10}$/, // South Korea BRN (10 digits)
    LT: /^LT[0-9]{9,12}$/, // Lithuania VAT (9 or 12 digits)
    LU: /^LU[0-9]{8}$/, // Luxembourg VAT (8 digits)
    LV: /^LV[0-9]{11}$/, // Latvia VAT (11 digits)
    MT: /^MT[0-9]{8}$/, // Malta VAT (8 digits)
    MX: /^[A-Z]{4}[0-9]{6}[A-Z0-9]{3}$/, // Mexico RFC (4 letters, 6 digits, 3 alphanumeric)
    NL: /^NL[0-9]{9}B[0-9]{2}$/, // Netherlands VAT (9 digits, B followed by 2 digits)
    NO: /^NO[0-9]{9}$/, // Norway VAT (9 digits)
    NZ: /^NZ[0-9]{9}$/, // New Zealand GST (9 digits)
    PL: /^PL[0-9]{10}$/, // Poland VAT (10 digits)
    PT: /^PT[0-9]{9}$/, // Portugal VAT (9 digits)
    RO: /^RO[0-9]{2,10}$/, // Romania VAT (2 to 10 digits)
    RU: /^RU[0-9]{10}$/, // Russia INN (10 digits)
    SA: /^SA[0-9]{15}$/, // Saudi Arabia VAT (15 digits)
    SE: /^SE[0-9]{12}$/, // Sweden VAT (12 digits)
    SG: /^SG[0-9]{9}[A-Z]$/, // Singapore UEN (9 digits followed by a letter)
    SI: /^SI[0-9]{8}$/, // Slovenia VAT (8 digits)
    SK: /^SK[0-9]{10}$/, // Slovakia VAT (10 digits)
    TH: /^[0-9]{13}$/, // Thailand VAT (13 digits)
    TR: /^TR[0-9]{10}$/, // Turkey TIN (10 digits)
    TW: /^[0-9]{8}$/, // Taiwan VAT (8 digits)
    US: /^[0-9]{2}-[0-9]{7}$/, // United States EIN (2 digits dash 7 digits)
    ZA: /^4[0-9]{9}$/, // South Africa VAT (10 digits starting with 4)
    XI: /^XI[0-9]{9}$/, // Northern Ireland VAT (9 digits)
};

// Function to validate VAT/Tax numbers using the taxIdPatterns
export const ValidateTaxId = (taxIdNumber: string, countryCode: string) => {
    const pattern = taxIdPatterns[countryCode.toUpperCase()];
    if (!pattern) {
        return false;
    }
    return pattern.test(taxIdNumber);
};

import { Injectable } from '@angular/core';
import { getUserLocation } from '@passbot/shared';

@Injectable({
    providedIn: 'root',
})
export class LocationService {
    private location: string; // 2 digit country code

    constructor() {
        void getUserLocation();
    }

    public async getUserLocation() {
        if (this.location) {
            return this.location;
        }

        const localLocation = localStorage.getItem('pb-location');

        if (localLocation) {
            this.location = localLocation;
            return this.location;
        }

        this.location = await fetch('https://ipapi.co/json')
            .then(async (res) => res.json())
            .then((data) => data.country_code)
            .catch(() => 'gb');

        localStorage.setItem('pb-location', this.location);

        return this.location;
    }
}

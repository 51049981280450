import { Injectable } from '@angular/core';
import { firstValueFrom, ReplaySubject } from 'rxjs';
import { CountryToCurrency } from '@passbot/shared';
import { LocationService } from './location.service';

export type Currency = 'USD' | 'GBP' | 'EUR';

@Injectable({
    providedIn: 'root',
})
export class CurrencyService {
    private currency: Currency;
    private readonly currency$ = new ReplaySubject<Currency>(1);

    constructor(private readonly locationService: LocationService) {}

    public getCurrency() {
        if (!this.currency) {
            void this.initCurrency();
        }

        return this.currency$.asObservable();
    }

    public getCurrencyAsync() {
        return firstValueFrom(this.getCurrency());
    }

    public setCurrency(currency: Currency) {
        this.currency = currency;
        this.currency$.next(currency);
        localStorage.setItem('pb-currency', currency);
    }

    private async initCurrency() {
        if (this.currency) {
            this.currency$.next(this.currency);
            return;
        }

        const localCurrency = localStorage.getItem('pb-currency') as Currency;

        if (localCurrency) {
            this.currency = localCurrency;
            this.currency$.next(this.currency);
            return;
        }

        const location = await this.locationService.getUserLocation();

        let currency = CountryToCurrency[location];

        if (!['USD', 'GBP', 'EUR'].includes(currency)) {
            currency = 'USD';
        }

        localStorage.setItem('pb-currency', currency);

        this.currency = currency as Currency;
        this.currency$.next(this.currency);
    }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { CurrencyService } from '../../services/currency.service';

@Component({
    selector: 'passbot-multi-currency-price',
    standalone: true,
    template: '<span [innerHTML]="price"></span>',
    imports: [CommonModule],
})
export class MultiCurrencyPriceComponent implements OnDestroy, OnInit {
    public price: SafeHtml;

    @Input() public USD: string;
    @Input() public GBP: string;
    @Input() public EUR: string;

    private observable: Subscription;

    constructor(private readonly currencyService: CurrencyService, private readonly sanitizer: DomSanitizer) {}

    public ngOnInit() {
        this.observable = this.currencyService.getCurrency().subscribe((currency) => {
            switch (currency) {
                case 'GBP':
                    this.price = this.sanitizer.bypassSecurityTrustHtml(`&pound;${this.GBP}`);
                    return;
                case 'EUR':
                    this.price = this.sanitizer.bypassSecurityTrustHtml(`&euro;${this.EUR}`);
                    return;
                case 'USD':
                default:
                    this.price = this.sanitizer.bypassSecurityTrustHtml(`&dollar;${this.USD}`);
                    return;
            }
        });
    }

    public ngOnDestroy() {
        this.observable.unsubscribe();
    }
}
